import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DragdropService } from '../_services/drag-drop.service';
import { ToastService } from '../_services/toast.service';
import { AccountService } from '../_services/account.service';
import { ImageModel } from '../_models/image.model';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { SwiperComponent } from 'swiper/angular';

@Injectable()
export class UserService {
  swiper!: SwiperComponent;
  missingUserDetails = new BehaviorSubject<boolean>(false);
  missingUserDetails$: Observable<boolean> =
    this.missingUserDetails.asObservable();

  missingPhone = new BehaviorSubject<boolean>(false);
  missingPhone$: Observable<boolean> = this.missingPhone.asObservable();

  profilePhoto = new BehaviorSubject<any>(null);
  profilePhoto$: Observable<any> = this.profilePhoto.asObservable();
  imagesArray: ImageModel[] = [];
  submitted = false;
  fileArr: any[] = [];
  imgArr: any[] = [];
  fileObj: any[] = [];
  form!: FormGroup;
  msg: string = '';
  progress: number = 0;
  subscriptionDaysLeft = '';
  promotionDaysLeft = '';
  trialEndDate = '';

  images = new BehaviorSubject<any[]>([]);
  images$: Observable<any[]> = this.images.asObservable();

  constructor(
    private dragdropService: DragdropService,
    private _toastService: ToastService,
    public fb: FormBuilder,
    private _accountService: AccountService,
    private _sanitizer: DomSanitizer
  ) {this.form = this.fb.group({
    images: [null],
  });}

  upload(e: any, type?: boolean | undefined) {
    

    if (!e.length) return;

    const fileListAsArray = Array.from(e);
    fileListAsArray.forEach((item, i) => {
      const file = e as any;
      const url: any = URL.createObjectURL(file[i]);
      this.imgArr.push(url);
      this.fileArr.push({ item, url: url });
    });

    let size = 0;
    this.fileArr.forEach((item) => {

      size = size + +(item.item.size / 10485760).toFixed(2);
      this.fileObj.push(item.item);
    });
    

    // Set files form control
    this.form.setValue({
      images: this.fileObj,
    });
    this.form.get('image')?.updateValueAndValidity();

    // Upload to server
    if (size < 5) {
      this.dragdropService.addFiles(this.form.value.images, type).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              if (event.total) {
                this.progress = Math.round((event.loaded / event.total) * 100);
                console.log(`Uploaded! ${this.progress}%`);
              }
              break;
            case HttpEventType.Response:
              this._toastService.presentToast();
              if (this.swiper) this.swiper.swiperRef.slideNext();
              setTimeout(() => {
                this.progress = 0;
                this.imgArr = [];
                this.fileArr = [];
                this.fileObj = [];
                this.submitted = true;
              }, 1000);
              this.getUserDashboard();

              break;
          }
        },
        (err) => {
          this.imgArr = [];
          this.fileArr = [];
          this.fileObj = [];
        }
      );
    }
  }

  getUserImages() {
    this._accountService.getPicturesForUser().subscribe((x: ImageModel[]) => {
      this.imagesArray = x.filter((x) => !x.profilePhoto);
      const map = this.imagesArray.map((image: ImageModel) => {
        const object = {
          id: image.id,
          isProfilePhoto: image.profilePhoto,
          approved: image.approved,
          data: this._sanitizer.bypassSecurityTrustUrl(
            this.getImageSrc(image.image?.data)
          ),
        };
        return object;
      });
      this.images.next(map);
    });
  }

  getImageSrc(imageData: Uint8Array): any {
    return URL.createObjectURL(
      new Blob([Buffer.from(imageData)], { type: 'image/webp' })
    );
  }

  getUserDashboard(skipImages?: boolean) {
    this.missingUserDetails.next(false);
    this.missingPhone.next(false);

    this._accountService.getUser().subscribe((x) => {
      this._accountService.userSubject.next(x);
      if (
        this._accountService.userValue &&
        this._accountService.userValue.subscriptionDetails
      ) {
        const startDateSubscription = moment.unix(
          this._accountService.userValue.subscriptionDetails?.startTime
        );
        const endDateSubscription = moment.unix(
          this._accountService.userValue.subscriptionDetails?.endTime
        );

        this.subscriptionDaysLeft =
          moment(endDateSubscription).format('DD/MM/YYYY HH:MM');
        if (this._accountService.userValue.subscriptionDetails.refundable) {
          this.trialEndDate = moment(startDateSubscription)
            .add(7, 'days')
            .format('DD/MM/YYYY HH:MM');
        }
      }

      if (
        this._accountService.userValue &&
        this._accountService.userValue.promotionDetails
      ) {
        const endDatePromotion = moment.unix(
          this._accountService.userValue.promotionDetails?.endTime
        );

        this.promotionDaysLeft =
          moment(endDatePromotion).format('DD/MM/YYYY h:mm');
      }

      const mandatory = ['genderId', 'cityId', 'dob'];
      if (this._accountService.userValue) {
        Object.entries(this._accountService.userValue.userDetails).forEach(
          (x) => {
            if (mandatory.includes(x[0]) && !x[1]) {
              this.missingUserDetails.next(true);
            }
          }
        );
        if (!this._accountService.userValue.contacts.phone) {
          this.missingPhone.next(true);
        }
        if (
          this._accountService.userValue.profilePhoto &&
          this._accountService.userValue.profilePhoto.image
        ) {
          const profilePhoto = {
            approved: this._accountService.userValue.profilePhoto.approved,
            data: this._sanitizer.bypassSecurityTrustUrl(
              this.getImageSrc(
                this._accountService.userValue?.profilePhoto.image.data
              )
            ),
          };

          this.profilePhoto.next(profilePhoto);
        }
        if (!skipImages) this.getUserImages();
      }
    });
  }
}
