import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomHttpService {
  constructor(private httpClient: HttpClient) {}

  public makeGetRequest(url: string, options?: any): Observable<any> {
    return this.httpClient.get(url, options);
  }

  public makePostRequest(
    url: string,
    body?: any,
    options?: any
  ): Observable<any> {
    return this.httpClient.post(url, body, options);
  }

  public makePutRequest(
    url: string,
    body?: any,
    options?: any
  ): Observable<any> {
    return this.httpClient.put(url, body, options);
  }

  public makeDeleteRequest(url: string, body?: any): Observable<any> {
    return this.httpClient.delete(url, body);
  }
}
