import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private _toastCtrl: ToastController,
    private _translate: TranslocoService
  ) {}

  async presentToast() {
    const toast = await this._toastCtrl.create({
      message: this._translate.translate('app.success'),
      duration: 3000,
      position: 'bottom',
      cssClass : 'success-toast'
    });

    await toast.present();
  }
}
