<ion-app>
  <ion-split-pane when="md" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-img
            class="logo"
            src="/assets/images/logo-white.png"
            class="ion-padding-horizontal"
          ></ion-img>
          <div class="items-container">
            <ion-menu-toggle
              auto-hide="false"
            >
              <ion-item
                [href]="url"
                lines="none"
                detail="false"
              >
              <ion-icon name="chevron-back-outline"></ion-icon>
                <ion-label>{{ "app.backToMain" | transloco }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
          <div class="buttons">
            <ng-container *ngIf="!accountService.userValue; else userLoggedIn">
              <ion-menu-toggle auto-hide="false">
                <a
                  class="register"
                  routerDirection="root"
                  [routerLink]="['register']"
                  >{{ "app.registerNow" | transloco }}</a
                >
              </ion-menu-toggle>
              <ion-menu-toggle auto-hide="false">
                <ion-button
                  shape="round"
                  color="primary"
                  [routerLink]="['login']"
                  routerDirection="root"
                  >{{ "app.login" | transloco }}</ion-button
                >
              </ion-menu-toggle>
             
            </ng-container>
            <ng-template #userLoggedIn>
              <div class="userLoggedIn">
                <ion-menu-toggle auto-hide="false">
                  <ion-item
                    button
                    lines="none"
                    detail="true"
                    [routerLink]="['account']"
                    routerLinkActive="selected"
                  >
                    <ion-icon
                      size="large"
                      slot="start"
                      name="person-circle-outline"
                    ></ion-icon>
                    <ion-label>{{ "user.profile" | transloco }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="false">
                  <ion-item
                    button
                    lines="none"
                    detail="true"
                    (click)="logout()"
                  >
                    <ion-icon
                      size="large"
                      slot="start"
                      name="log-out-outline"
                    ></ion-icon>
                    <ion-label>{{ "app.logout" | transloco }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
                <ion-item class="item-text-wrap">
                  <ion-label>{{
                    accountService.userValue?.contacts?.email
                  }}</ion-label>
                </ion-item>
              </div>
            </ng-template>
          </div>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"> </ion-router-outlet>
  </ion-split-pane>
</ion-app>
<ng-template #customLoadingTemplate>
  <div class="custom-class">
    <img src="../assets/images/loading2.gif" />
  </div>
</ng-template>
<ngx-loading
  [show]="loaderService.getLoading() === true"
  [template]="customLoadingTemplate"
></ngx-loading>
<p class="seo-text">Escorte de lux in Romania.
  Site de escorte.
  Escorte de lux.
  Escorte Bucuresti.
  Escorte Cluj.
  Escorte Constanta.
  Escorte Sibiu.
  Escorte Timisoara.
  Escorte Iasi.
  Escorte online.
  Escorte publi24.
  Matrimoniale Romania.
  Destinația ta pentru Aventuri de o Noapte!
  Cauti aventuri pasionale și fără obligații? Ai ajuns în locul potrivit! Love69 este cea mai nouă și mai incitantă platformă de escorte in Romania. Ne dedicăm să-ți aducem o experiență de navigare simplă, sigură și extrem de satisfăcătoare, astfel încât să poți să-ți îndeplinești fanteziile și să întâlnești oameni care împărtășesc aceleași dorințe. De ce Love69?
  Escorte De Lux: Aici e locul ideal in care s iti satisfaci fanteziile fără frică de judecată sau indiscreție.
  Conectare Rapidă: Gaseste parteneri potriviți în funcție de preferințele tale. În câteva clicuri, poți să te conectezi cu oameni care îți stârnesc interesul.
  Comunitate Deschisă: Suna escortele sau contacteaza-le pe whatsapp. Experiente de o noapte, experiențe pasagere, fără angajamente pe termen lung.
  Suntem toți aici pentru același lucru: distracție și aventuri de o noapte.
  Platformă Ușor de Utilizat: Interfața noastră prietenoasă îți permite să organizezi întâlniri fără efort.
  Încurajăm respectul și consensul în toate interacțiunile. Comunitatea noastră pune un accent deosebit pe comunicarea deschisă și pe asigurarea că toți utilizatorii se simt în siguranță și respectați.
  Utilizează funcția de căutare pentru a găsi escorte care te atrag.
  Trimite mesaje pe whatsapp si stabileste intalnirea.
  Planifică-ți Aventura: Odată ce ai găsit escorta care îți stârnește interesul, stabilește o întâlnire pentru a vă cunoaște mai bine și pentru a vă bucura de momente pasionale.
  Experimentează senzații intense pe cea mai recentă platformă de escorte din Romania. Conectează-te cu persoane care își doresc aceeași experiență fără compromisuri. Alătură-te acum și transformă nopțile tale în momente memorabile!
  Platforma noua pentru cei care caută aventuri pasionale și intense de o noapte! Suntem aici pentru a vă oferi o experiență de dating fără inhibiții, unde vă puteți bucura de întâlniri incitante și fără obligații.</p>
