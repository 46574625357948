import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import { AlertController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from './user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _accountService: AccountService,
    private _alertCtrl: AlertController,
    private _translate: TranslocoService,
    private userService : UserService
  ) {}

  async showAlert(messageKey: string) {
    const alert = await this._alertCtrl.create({
      header: this._translate.translate('app.error'),
      message: this._translate.translate(messageKey),
      buttons: ['OK'],
    });

    await alert.present();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401].includes(err.status) && this._accountService.userValue) {
          // auto logout if 401 or 403 response returned from api
          this._accountService.logout().subscribe(()=>{
            this._accountService.userSubject.next(null);
            this.userService.profilePhoto.next(null);
          });
        }

        const error = (err && err.error && err.error.message) || err.statusText;
        console.error(err);
        if (err.error.message !=='error.invalidLoginToken') {
          this.showAlert(error);
        }

        return throwError(() => error);
      })
    );
  }
}
