import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { User } from './_models';
import { AccountService } from './_services/account.service';
import { MiscService } from './_services/misc.service';
import { marker } from '@ngneat/transloco-keys-manager/marker';
import { TranslocoService } from '@ngneat/transloco';
import { LoaderService } from './_services/loader.service';
import { UserService } from './_helpers/user.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  cookieValue!: string;
  user?: User | null;
  public url = '';

  @ViewChild('customLoadingTemplate', { static: false })
  customLoadingTemplate!: TemplateRef<any>;

  constructor(
    private _router: Router,
    public miscService: MiscService,
    public accountService: AccountService,
    private translocoService: TranslocoService,
    public loaderService: LoaderService,
    public userService: UserService
  ) {
    marker('error.accountDeleted');
    marker('error.accountSuspended');
    marker('error.badRequest');
    marker('error.emailAlreadyRegistered');
    marker('error.emailNotVerified');
    marker('error.emailVeriCodeInvalid');
    marker('error.emailVeriCodeMissing');
    marker('error.internalServerError');
    marker('error.invalidCredentials');
    marker('error.invalidEmailFormat');
    marker('error.invalidLoginToken');
    marker('error.invalidNameFormat');
    marker('error.invalidPasswordFormat');
    marker('error.tocNotAccepted');
    marker('error.invalidNumberFormat');
    marker('error.emailVeriWait2Mins');
    marker('error.passResetWait2Mins');
    marker('error.error.passwordsDoNotMatch');
    marker('error.passResetVeriCodeMissing');
    marker('error.passResetVeriCodeInvalid');
    marker('error.invalidImageFormat');
    marker('error.invalidImageId');
    marker('error.invalidHeight');
    marker('error.userUnderAge');
    marker('error.invalidId');
    marker('error.invalidOrderCode');
    marker('error.invalidTransId');
    marker('error.trialPeriodExpired');
    marker('error.noActiveSubscription');
    marker('error.failedSendingSms');
    marker('error.refundUnsuccessful');

    this.accountService.user.subscribe((x) => (this.user = x));
  }

  ngOnInit() {

    const lang = window.location.origin.includes('.eu') ? 'en' : 'ro';
    this.translocoService.setActiveLang(lang);

    if(window.location.origin.includes('localhost')){
      this.accountService.apiUrl ='https://adventures-dev.love69.eu/api';
        this.url = "https://adeventures-dev.love69.eu"
    } else {
      this.accountService.apiUrl = window.location.origin + '/api';
      if(lang === 'ro'){
        this.url = "https://www.love69.ro";
      } else {
        this.url = "https://www.love69.eu";
      }
      
    }

    this.miscService.getMetadata();
    const data = this.accountService.getUser().subscribe({
      next: (data) => {
        this.accountService.userSubject.next(data);
      },
      error: (error: any) => {
        console.log('Error caught in component: ', error);
      },
    });
  }

  logout() {
    this.accountService.logout().subscribe((x) => {
      this.accountService.userSubject.next(null);
      this.userService.profilePhoto.next(null);
      this._router.navigate(['/login']);
    });
  }
}
