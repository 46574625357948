import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountService } from './account.service';
@Injectable({
  providedIn: 'root',
})
export class DragdropService {
  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) {}
  addFiles(images: File[], type: boolean | undefined) {

    let arr: any[] = [];
    let formData = new FormData();
    arr.push(images);
    arr[0].forEach((_: any, i: number) => {
      formData.append('image', arr[0][i]);
    });
    if (type) {
      return this.accountService.uploadProfilePicture(formData);
    } else {
      return this.accountService.uploadPictures(formData);
    }
  }
}
