import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Metadata } from '../_models/misc-service.model';
import { CustomHttpService } from './custom-http.service';
import * as moment from 'moment';
import { AccountService } from './account.service';
import { User } from '../_models';
import { LoaderService } from './loader.service';
import { Share } from '@capacitor/share';
import { Platform, ToastController } from '@ionic/angular';
import * as copy from 'copy-to-clipboard';

@Injectable({ providedIn: 'root' })
export class MiscService {
  public miscMetadata: Metadata = {
    userDetails: {
      profileName: {
        mandatory: true,
        editable: true,
      },
      genderId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      nationalityId: {
        mandatory: true,
        editable: true,
      },
      dob: {
        mandatory: true,
        editable: true,
      },
      bio: {
        mandatory: true,
        editable: true,
      },
      cityId: {
        mandatory: true,
        editable: true,
      },
      countryId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
    },
    contacts: {
      phone: {
        mandatory: true,
        editable: true,
      },
      whatsapp: {
        mandatory: true,
        editable: true,
      },
    },
    userAttributes: {
      height: {
        mandatory: true,
        editable: true,
      },
      weight: {
        mandatory: true,
        editable: true,
      },
      hairColorId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      eyeColorId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      bodyTypeId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      smoking: {
        mandatory: true,
        editable: true,
      },
      tattoo: {
        mandatory: true,
        editable: true,
      },
      piercing: {
        mandatory: true,
        editable: true,
      },
    },
    profilePhoto: {
      mandatory: true,
      editable: true,
    },
    languages: {
      mandatory: true,
      editable: true,
      values: [
        {
          id: 0,
          value: 'string',
        },
      ],
    },
    hidden: {
      mandatory: true,
      editable: true,
    },
    countryCitiesMap: [
      {
        id: 141,
        country: 'Romania',
        cities: [
          {
            id: 1,
            value: '2 mai',
          },
          {
            id: 2,
            value: 'Aiud',
          },
        ],
      },
    ],
  };
  constructor(
    private customHttpService: CustomHttpService,
    private loaderService: LoaderService,
    public accountService: AccountService,
    private _platform: Platform,
    private _toastCtrl: ToastController
  ) {}

  loading = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loading.asObservable();

  getMetadata() {
    return this.customHttpService
      .makeGetRequest(`${this.accountService.apiUrl}/misc/app-metadata`)
      .subscribe((res) => {
        this.miscMetadata = res;
      });
  }

  getYears(dob: number) {
    const b = moment.unix(dob).format('YYYY-MM-DD');
    const years = moment().diff(b, 'years');
    return years;
  }

  toggleFavourite(user: User, event: Event) {
    event.stopPropagation();

    user.favorite = !user.favorite;
    this.loaderService.showLoading = false;
    this.accountService.addRemoveUserToFav(user.userId).subscribe(() => {
      this.loaderService.showLoading = true;
    });
  }

  async shareUser(user: User, event: Event) {
    const link = `${window.location.origin + '/user/' + user.uuid}`;

    if (this._platform.is('desktop')) {
      copy(link);
      const toast = await this._toastCtrl.create({
        message: 'Link copiat cu success',
        duration: 2000,
        position: 'bottom',
        cssClass : 'success-toast'
      });
  
      await toast.present();
    } else {
      await Share.share({
        title: 'Love69 Aventuri',
        text: 'Experimentează senzații intense pe cel mai nou site de aventuri de o noapte',
        url: link,
        dialogTitle: 'Share with buddies',
      });
    }
  }
}
