import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '../_models';
import { CustomHttpService } from './custom-http.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AccountService {
  public userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;
  public apiUrl ='';

  constructor(private customHttpService: CustomHttpService) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  public get subscriptionExpired(){
    if(!this.userValue?.subscriptionDetails) return;
    return  this.userValue?.subscriptionDetails?.endTime < Date.now();   
  }


  public get promotionsExpired(){
    if(!this.userValue?.promotionDetails) return;
    return  this.userValue?.promotionDetails?.endTime < Date.now();
  }

  getUser(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/subscriber/details`,
      {
        withCredentials: true,
      }
    );
  }

  getAccount(uuid : string): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/public/user/${uuid}`,
    );
  }


  updateUserWhatsapp(whatsapp: string): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/whatsapp`,
      { whatsapp: whatsapp },
      {
        withCredentials: true,
      }
    );
  }

  updateUserVisibility(hidden: any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/visibility`,
      { hidden: hidden },
      {
        withCredentials: true,
      }
    );
  }

  updateUserAttributes(form: any): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/attributes`,
      form,
      {
        withCredentials: true,
      }
    );
  }

  updateUserDetails(form: any): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/details`,
      form,
      {
        withCredentials: true,
      }
    );
  }

  requestPhoneVerification(phone: any) {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/request-phone-verification`,
      { phone },
      { withCredentials: true }
    );
  }

  updatePhone(phone: string, code: number) {
    const obj = { phone: phone, verificationCode: code };

    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/update-phone`,
      obj,
      { withCredentials: true }
    );
  }

  login(email: string, password: string): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/auth/login`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );
  }

  logout(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/auth/logout`,
      {
        withCredentials: true,
      }
    );
  }

  register(user: any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber`,
      user
    );
  }

  verifyEmail(email: any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/request-email-verification`,
      { email },
      { withCredentials: true }
    );
  }

  updateEmail(email: string, code: number): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/update-email`,
      { email: email, verificationCode: code },
      { withCredentials: true }
    );
  }

  requestPasswordReset(email: any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/auth/request-password-reset`,
      { email },
      { withCredentials: true }
    );
  }

  resetPassword(form: any): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/auth/reset-password`,
      form,
      { withCredentials: true }
    );
  }

  updatePassword(password: string, confirmPassword: string): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/auth/update-password`,
      { password: password, confirmPassword: confirmPassword },
      { withCredentials: true }
    );
  }

  deleteOwnAccount(): Observable<any> {
    return this.customHttpService.makeDeleteRequest(
      `${this.apiUrl}/subscriber`,
      {
        withCredentials: true,
      }
    );
  }

  getPicturesForUser(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/subscriber/images`,
      {
        withCredentials: true,
      }
    );
  }

  uploadPictures(formData: FormData): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/images`,
      formData,
      {
        withCredentials: true,
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  uploadProfilePicture(formData: FormData): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/profile-photo`,
      formData,
      {
        withCredentials: true,
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  deleteImages(idArray: number[]): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/delete-images`,
      { imageIds: idArray },
      {
        withCredentials: true,
      }
    );
  }
  updateProfilePhoto(id: number): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/profile-photo`,
      { imageId: id },
      {
        withCredentials: true,
      }
    );
  }

  requestAccountReverification(): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/request-account-reverification`,
      null,
      {
        withCredentials: true,
      }
    );
  }

  requestIdentityVerification(): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/request-identity-verification`,
      null,
      {
        withCredentials: true,
      }
    );
  }

  updateViewCount(userId:number): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/views`,
      { userId }
    );
  }

  updateContactViewCount(userId:number, contactType : 'phone' | 'whatsapp'): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/contact-view`,
      { userId , contactType}
    );
  }

  getUserList(sentUserIds: number[], filters: any): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/get-users`,
      { sentUserIds, filters }
    );
  }

  addRemoveUserToFav(userId:number): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/favorite`,
      { userId }
    );
  }

  getFavouritesUserList(sentUserIds: number[], filters: any): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/get-users?favorite=true`,
      { sentUserIds, filters }
    );
  }

  getPromotedUserList(sentUserIds: number[], filters: any): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/get-promoted-users`,
      { sentUserIds , filters}
    );
  }

  getSubscriptions(): Observable<any[]> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/public/subscriptions`
    );
  }

  getSubscriberSubscriptions(): Observable<any[]> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/subscriber/subscriptions`,
      { withCredentials: true }
    );
  }

  getPromotions(): Observable<any[]> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/subscriber/promotions`,
      { withCredentials: true }
    );
  }

  initiatePaymentSubscription(id: number): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/payment/subscription`,
      { id }, {withCredentials : true}
    );
  }

  initiatePaymentPromotion(id: number): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/payment/promotion`,
      { id }, {withCredentials : true}
    );
  }
  initiatePaymentIdentification(): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/payment/identity-verification`,
      null, {withCredentials : true}
    );
  }

  updatePayment(orderCode: number, transId : number): Observable<any[]> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/order-details`,
      { orderCode , transId }, {withCredentials : true}
    );
  }

  renewSubscription(): Observable<any[]> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/subscription`,
      null, {withCredentials : true}
    );
  }
  cancelSubscription(): Observable<any[]> {
    return this.customHttpService.makeDeleteRequest(
      `${this.apiUrl}/subscriber/subscription`,
      {withCredentials : true}
    );
  }
  
}
